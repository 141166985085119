import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";
import { l } from "./languageUtils";
import { useForm } from "react-hook-form";
import Lang from "./Lang.astro";
// import mapImage from "../assets/images/map.png";
import Icon from "./Icon";
import { twMerge } from "tailwind-merge";
import NoCoverageFormModal from "./noCoverageFormModal";
import { gtmLogProvinceClick, type Province } from "../utils/gtm/provinceClicked";
import { provinces } from "../utils/provinces";

interface ListExternalLinksModalProps {
  lang: "CA-fr" | "en";
}
// DO NOT FORGET THE CLIENT DIRECTIVE client:load in the ListExternalLinksModal component that is being called in index.astro
export default function ListExternalLinksModal({ lang }: ListExternalLinksModalProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [link, setLink] = useState<string | null>(null);

  const [reminderFormModalOpen, setReminderFormModalOpen] = useState(false);
  const [reminderProvince, setReminderProvince] = useState<string | null>(null);

  const openReminderFormModal = (e: React.MouseEvent, province: Province) => {
    e.preventDefault();
    gtmLogProvinceClick({ province });
    setReminderProvince(province);
    setReminderFormModalOpen(true);
  };

  const openModal = (e: React.MouseEvent, link: string) => {
    e.preventDefault();
    setIsOpen(true);
    setLink(link);
  };

  function closeModal() {
    setIsOpen(false);
  }

  const openLink = () => {
    if (link) {
      window.open(link, "_blank");
      setLink(null);
      setIsOpen(false);
    }
  };

  const availableProvinces: {
    id: Province;
    name: string;
    url: string;
    color: Boolean;
  }[] = [
      {
        id: "alberta",
        name: "Alberta",
        url: provinces.alberta.url,
        color: true,
      },
      {
        id: "british_columbia",
        name: l("British Columbia (through the EDRD)", "Colombie-Britannique (par le MOMR)", lang),
        url: l("https://www2.gov.bc.ca/gov/content/health/health-drug-coverage/pharmacare-for-bc-residents/what-we-cover/exceptional-funding-edrd", "https://www2.gov.bc.ca/gov/content/health/health-drug-coverage/pharmacare-for-bc-residents/what-we-cover/exceptional-funding-edrd", lang),
        color: true,
      },
      {
        id: "quebec",
        name: l("Quebec", "Québec", lang),
        url: l("https://www.ramq.gouv.qc.ca/sites/default/files/documents/non_indexes/liste_med_2024-04-11_en.pdf", "https://www.ramq.gouv.qc.ca/sites/default/files/documents/non_indexes/liste_med_2024-05-23_fr_0.pdf", lang),
        color: true,
      },
      { id: "ontario", name: l("Ontario (through the EAP)", "Ontario (par le PAE)", lang), url: "#", color: true },
      {
        id: "new_brunswick",
        name: l("New Brunswick", "Nouveau-Brunswick", lang),
        url: "https://www2.gnb.ca/content/dam/gnb/Departments/h-s/pdf/en/NBDrugPlan/NewBrunswickDrugPlansFormulary.pdf",
        color: true,
      },
      {
        id: "nova_scotia",
        name: l("Nova Scotia", "Nouvelle-Écosse", lang),
        url: "https://novascotia.ca/dhw/pharmacare/documents/formulary.pdf",
        color: true,
      },
      {
        id: "saskatchewan",
        name: l("Saskatchewan", "Saskatchewan", lang),
        url: "https://formulary.drugplan.ehealthsask.ca/SearchFormulary/BG/946144",
        color: true,
      },
      {
        id: "manitoba",
        name: l("Manitoba", "Manitoba", lang),
        url: "#",
        color: true,
      },
      {
        id: "northwest_territories",
        name: l("Northwest Territories", "Territoires du Nord-Ouest", lang),
        url: "#",
        color: false,
      },
      {
        id: "prince_edward_island",
        name: l("Prince Edward Island", "Île-du-Prince-Édouard", lang),
        url: "#",
        color: false,
      },
      {
        id: "newfoundland_and_labrador",
        name: l("Newfoundland and Labrador", "Terre-Neuve-et-Labrador", lang),
        url: "#",
        color: false,
      },
      {
        id: "nunavut",
        name: l("Nunavut", "Nunavut", lang),
        url: "#",
        color: false,
      },
      {
        id: "yukon",
        name: l("Yukon", "Yukon", lang),
        url: "#",
        color: false,
      },
    ];

  return (
    <div className="break-words">
      <Fragment>
        <p className="text-lg font-semibold text-center pt-2 text-gray-700">{l("Please select a province", "Veuillez sélectionner une province", lang)}</p>
        <ul className="text-white font-semibold">
          {availableProvinces.map(province => (
            <li key={province.id}>
              <a
                target="_blank"
                href={province.url}
                className={twMerge(
                  "block text-center p-2 rounded-full my-2 bg-[#e94270] text-white active:bg-gray-400 active:text-white",
                  province.color === false ? "bg-[#838892]" : "",
                )}
                onClick={e => {
                  if (province.url === "#") {
                    openReminderFormModal(e, province.id);
                  } else {
                    gtmLogProvinceClick({ province: province.id });
                    openModal(e, province.url);
                  }
                }}
              >
                {province.name}
              </a>
            </li>
          ))}
        </ul>

        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
            <div className="flex items-center justify-center min-h-screen">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <DialogPanel className="fixed inset-0 bg-black opacity-30" />
              </TransitionChild>

              <button
                type="button"
                className="absolute top-0 right-0 bg-blue-headers rounded-bl-lg flex items-center justify-center text-3xl md:text-5xl hover:bg-[#5A4A88] hover:text-blue-headers focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#5A4A88] cursor-pointer"
                onClick={closeModal}
              >
                <Icon
                  name="close"
                  className={twMerge(
                    " h-14 w-14 block bg-blue-headers p-2 text-white hover:bg-[#5A4A88] rounded-bl-lg",
                  )}
                />
              </button>
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="relative bg-[#f8f8f8] rounded-3xl p-6 max-w-3xl mx-auto flex flex-col justify-center items-center">
                  <Icon
                    as="div"
                    name="emptyExclamation"
                    className="flex-shrink-0 text-center text-red-500 bg-red-200 h-10 w-10 rounded-full mb-4 p-2"
                  />
                  <div>
                    {link === "#" ? (
                      <p className="text-sm text-[#6C6C6C] text-center">
                        Coverage criteria are not yet listed. Please check back for updates.
                      </p>
                    ) : lang === "CA-fr" ? (
                      <p className="text-sm text-[#6C6C6C] text-center">
                        Vous quittez maintenant le site Web <strong>empavelicoverage.ca.</strong> Sobi Canada, Inc. n'est
                        pas responsable et ne supervise, ne gère, n'exploite, ne fournit ou n'approuve d'aucune façon un
                        site Web de tiers ou tout produit, service ou contenu de ce site Web de tiers. Nos conditions
                        générales d'utilisation et notre politique de confidentialité ne s'appliquent à aucun site Web de
                        tiers et Sobi Canada, Inc. vous recommande donc de consulter les conditions d'utilisation, la
                        politique de confidentialité et/ou les politiques de sécurité des données de ces sites Web de
                        tiers.
                      </p>
                    ) : (
                      <p className="text-sm text-[#6C6C6C] text-center">
                        You are now leaving <strong>empavelicoverage.ca.</strong> Sobi Canada, Inc. is not responsible
                        for and does not in any way oversee, manage, operate, provide, or endorse any third-party
                        website or any products, services, or content on such third-party website. Our Terms of Use and
                        Privacy Policy are not applicable to any third-party website and so Sobi Canada, Inc. recommends
                        that you review the terms of use, privacy policy and/or data security policies of any such
                        third-party websites.
                      </p>
                    )}
                  </div>

                  <div className="mt-4 flex justify-center space-x-2">
                    {link === "#" ? (
                      <button
                        type="button"
                        onClick={closeModal}
                        className="px-8 py-2 bg-gray-400 text-white rounded-full hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5A4A88]"
                      >
                        {l("Close", "Fermer", lang)}
                      </button>
                    ) : (
                      <>
                        <button
                          type="button"
                          onClick={() => setIsOpen(false)}
                          className="px-8 py-2 bg-gray-400 text-white rounded-full hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5A4A88]"
                        >
                          {l("Cancel & stay here", "Annuler", lang)}
                        </button>
                        <button
                          type="button"
                          onClick={openLink}
                          className="px-8 py-2 bg-[#37B2C4] hover:bg-[#a8dbe3] text-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5A4A88]"
                        >
                          {l("Continue to link", "Continuer", lang)}
                        </button>
                      </>
                    )}
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </Dialog>
        </Transition>

        {/* 
        
        
        
        
        
        
        
        
        No link modal
        
        */}
        <NoCoverageFormModal
          isOpen={reminderFormModalOpen}
          setIsOpen={setReminderFormModalOpen}
          province={reminderProvince}
          lang={lang}
        />
      </Fragment>
    </div>
  );
}
